import React from 'react';
import google from '../../assets/google.png'
import shopify from '../../assets/shopify.png'
import slack from '../../assets/slack.png'
import dropbox from '../../assets/dropbox.png'
import atlassian from '../../assets/atlassian.png'
import './brand.css';

const Brand = () => {
  return (
    <div className='section__padding gpt3__brand'>
      <div className='gpt3__brand-logos'>
        <img src={google} alt="google" />
      </div>
      
      <div className='gpt3__brand-logos'>
        <img src={slack} alt="slack" />
      </div>
      
      <div className='gpt3__brand-logos'>
        <img src={atlassian} alt="atlassian" />
      </div>
      
      <div className='gpt3__brand-logos'>
        <img src={dropbox} alt="dropbox" />
      </div>
      
      <div className='gpt3__brand-logos'>
        <img src={shopify} alt="shopify" />
      </div>
      

    </div>
  )
}

export default Brand