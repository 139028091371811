import React from 'react';
import ai from '../../assets/ai.png';
import people from '../../assets/people.png';
import './header.css';


const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>

      <div className="gpt3__header-content">
        <h1 className='gradient__text'>
          Let’s Build Something <br />
          amazing with GPT-3 <br />
          OpenAI
        </h1>
        <p>
          Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.
        </p>

        <div className="gpt3__header-content_email">
          <input type="email" placeholder='Your Email Address' />
          <button type='button'>
            Get Started
          </button>
        </div>

        <div className="gpt3__header-content_people">
          <img src={people} alt="people" />
          <p>
            1,600 people requested access a visit in last 24 hours
          </p>
        </div>
      </div>

      <div className="gpt3__header-img_container">
        <img src={ai} alt="AI" />
      </div>

    </div>
  )
}

export default Header