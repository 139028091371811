import React from 'react'
import { Article } from '../../components';
import { blog1, blog2, blog3, blog4, blog5 } from './imports.js';
import './blog.css';

const Blog = () => {
  const style_1 = {
    para: {
      fontFamily: 'var(--font-family)',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '15px',
      lineHeight: '35px',
      color: '#FFF',
    },
    title: {
      fontFamily: 'var(--font-family)',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '25px',
      lineHeight: '30px',
      color: '#FFF',
    }

  }

  const style_2 = {
    para: {
      fontFamily: 'var(--font-family)',
      fontWeight: '700',
      fontSize: '15px',
      lineHeight: '35px',
      color: '#FFF',
    },
    title: {
      fontFamily: 'var(--font-family)',
      fontWeight: '800',
      fontSize: '19px',
      lineHeight: '30px',
      color: '#FFF',
    }
  }

  return (
    <div className='gpt3__blog section__padding' id='blog'>
      <div className="gpt3__blog-heading">
        <h1 className='gradient__text'>A lot is happening,
          <br /> We are blogging about it.</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupA">
          <Article
            style={style_1}
            img={blog1}
            date={'Sep 26 2021'}
            title={'GPT-3 and Open  AI is the future. Let us exlore how it is?'} />
        </div>
        <div className="gpt3__blog-container_groupB">
          <Article
            style={style_2}
            img={blog2}
            date={'Sep 26 2021'}
            title={'GPT-3 and Open  AI is the future. Let us exlore how it is?'} />
          <Article
            style={style_2}
            img={blog3}
            date={'Sep 26 2021'}
            title={'GPT-3 and Open  AI is the future. Let us exlore how it is?'} />
          <Article
            style={style_2}
            img={blog4}
            date={'Sep 26 2021'}
            title={'GPT-3 and Open  AI is the future. Let us exlore how it is?'} />
          <Article
            style={style_2}
            img={blog5}
            date={'Sep 26 2021'}
            title={'GPT-3 and Open  AI is the future. Let us exlore how it is?'} />
        </div>
      </div>
    </div>
  )
}

export default Blog