import React from 'react';
import './article.css'

const article = ({style, img, date, title}) => {
  console.log(style)
  return (
    <div className='gpt3__blog-container_article'>
      <div className='gpt3__blog-container_article-image'>
      <img src={img} alt={img} />
      </div>
      <div className="gpt3__blog-container_article-content">
        <div className="gpt3__blog-container_article-content_heading">
        <p style={style.para}>{date}</p>
        <h1 style={style.title}>{title}</h1>
        </div>
        <p style={style.para}>Read full article</p>
      </div>

    </div>
  )
}

export default article